var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "equipment", attrs: { id: "ruleWrapper" } }, [
    _c("div", { staticClass: "e_content bgFFF" }, [
      _c(
        "div",
        { staticClass: "ruleMessage1" },
        [
          _vm._m(0),
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: { model: _vm.formInline, "label-width": "100px" },
            },
            [
              _c(
                "div",
                { staticClass: "f-top" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("searchModule.Event_Name"),
                        required: "",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "250px" },
                        attrs: {
                          type: "text",
                          placeholder: "请输入活动名称",
                          "auto-complete": "off",
                          maxlength: "20",
                        },
                        model: {
                          value: _vm.formInline.activityName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formInline,
                              "activityName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formInline.activityName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "关联优惠券", required: "" } },
                    [
                      _c("ticket-item", {
                        ref: "ticketFrom",
                        model: {
                          value: _vm.formInline,
                          callback: function ($$v) {
                            _vm.formInline = $$v
                          },
                          expression: "formInline",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "发放群组", required: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.formInline.publishGroup,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "publishGroup", $$v)
                            },
                            expression: "formInline.publishGroup",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "按用户标签发放", value: 0 },
                          }),
                          _c("el-option", {
                            attrs: { label: "按手机号发放", value: 1 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.formInline.publishGroup === 0
                    ? _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.formInline.tags,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formInline, "tags", $$v)
                                },
                                expression: "formInline.tags",
                              },
                            },
                            _vm._l(_vm.labelList, function (item) {
                              return _c("el-option", {
                                key: item.labelId,
                                attrs: {
                                  label: item.labelName,
                                  value: item.labelId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formInline.publishGroup === 1
                    ? _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.dialogVisible = true
                                },
                              },
                            },
                            [_vm._v("批量" + _vm._s(_vm.$t("button.import")))]
                          ),
                          _c(
                            "el-dialog",
                            {
                              attrs: {
                                title: "批量导入",
                                visible: _vm.dialogVisible,
                                width: "600px",
                              },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.dialogVisible = $event
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _vm._v(
                                    "1.请先获取批量导入的Excel模板将需要添加的信息填写后进行上传该Excel文件， 上传非规范文件可能造成数据错误，"
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: { click: _vm.exportModel },
                                    },
                                    [_vm._v("点击下载")]
                                  ),
                                  _vm._v("模板文件。 "),
                                ],
                                1
                              ),
                              _c("div", [
                                _vm._v(" 2.请选择上传文件，并上传。 "),
                              ]),
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "20px" } },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      attrs: {
                                        action: "",
                                        "on-remove": _vm.handleRemove,
                                        limit: 1,
                                        "on-exceed": _vm.handleExceed,
                                        "http-request": _vm.handleFiles,
                                        "file-list": _vm.fileList,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary",
                                          },
                                        },
                                        [_vm._v("浏览")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "dialog-footer",
                                  staticStyle: { "text-align": "center" },
                                  attrs: { slot: "footer" },
                                  slot: "footer",
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.dialogVisible = false
                                        },
                                      },
                                    },
                                    [_vm._v("上传")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.dialogVisible = false
                                        },
                                      },
                                    },
                                    [_vm._v("取 消")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "推送通知", required: "" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.formInline.couponPushMode,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "couponPushMode", $$v)
                            },
                            expression: "formInline.couponPushMode",
                          },
                        },
                        [
                          _c("el-checkbox", { attrs: { label: 0 } }, [
                            _vm._v("车主端"),
                          ]),
                          _c("el-checkbox", { attrs: { label: 1 } }, [
                            _vm._v("短信推送"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            margin: "0 auto",
            width: "250px",
            "margin-bottom": "20px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.submit },
            },
            [_vm._v("提交")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "title" }, [
      _c("div", { staticClass: "title_icon" }),
      _c("span", [_vm._v("添加活动")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }